import { MID_SCROLL_ARRAY, SECTION_SOURCE } from '@utils/constants';

export const LISTING_ITEM_CLASS = [
  {
    sectionSource: SECTION_SOURCE.LANGUAGE,
    className: "browse-by-lang-listing",
  },
  {
    sectionSource: SECTION_SOURCE.GENRE,
    className: "browse-by-genre-listing",
  },
  {
    sectionSource: SECTION_SOURCE.PROVIDER,
    className: "provider-listing",
  },
  {
    sectionSource: SECTION_SOURCE.CATEGORY,
    className: "browse-by-category-listing",
  },
  // {
  //   sectionSource: SECTION_SOURCE.BINGE_CHANNEL,
  //   className: "live-channel-listing",
  // },
  {
    sectionSource: SECTION_SOURCE.BINGE_DARSHAN_LIVE,
    className: "listing-darshan-live-item",
  },
  {
    sectionSource: SECTION_SOURCE.GENRE_RAIL_FOR_GAMES,
    className: "browse-by-genre-listing",
  },
];

export const SPECIAL_SECTION_SOURCE = [SECTION_SOURCE.PROVIDER, SECTION_SOURCE.LANGUAGE, SECTION_SOURCE.GENRE, SECTION_SOURCE.CATEGORY, SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE, SECTION_SOURCE.GENRE_RAIL_FOR_GAMES, SECTION_SOURCE.MID_BANNER_GAMES, SECTION_SOURCE.LIVE_EVENT_BANNER, ...MID_SCROLL_ARRAY]


export const HIDE_CROWN_ON_SOURCES = [SECTION_SOURCE.PROVIDER, SECTION_SOURCE.LANGUAGE, SECTION_SOURCE.GENRE, SECTION_SOURCE.CATEGORY, SECTION_SOURCE.BINGE_DARSHAN_LIVE, SECTION_SOURCE.GENRE_RAIL_FOR_GAMES, SECTION_SOURCE.MID_BANNER_GAMES, SECTION_SOURCE.LIVE_EVENT_BANNER, ...MID_SCROLL_ARRAY]
